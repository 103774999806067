import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem } from 'react-bootstrap';
import Gravatar from './Gravatar';

const AdminCurrentUser = (props) => {
  return (
    <div className="current-user">
      <Dropdown id="current-user-dropdown" pullRight>
        <Dropdown.Toggle noCaret bsStyle="link">
          <div className="current-user-box">
            <span className="current-user-name" title={props.user.get('name')}>
              {props.user.get('name')}
            </span>
            <span className="current-user-email" title={props.user.get('email')}>
              {props.user.get('email')}
            </span>
          </div>
          <Gravatar user={props.user} size={40} className="current-user-avatar" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {props.canManageApps && (
            <MenuItem href={props.urlTemplates.get('manageApps')}>Manage Applications</MenuItem>
          )}
          <MenuItem href={props.urlTemplates.get('changePassword')}>
            My Account &amp; Organizations
          </MenuItem>
          <MenuItem divider />
          <MenuItem href={props.urlTemplates.get('logout')}>Logout</MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

AdminCurrentUser.propTypes = {
  user: PropTypes.object.isRequired,
  canManageApps: PropTypes.bool.isRequired,
  urlTemplates: PropTypes.object.isRequired
};

export default AdminCurrentUser;
