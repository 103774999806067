import React from 'react';
import useLocalStorage from '../../utils/useLocalStorage';
import Tooltip from '../Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Project } from './types';
import { LOCAL_STORAGE_FAV_PROJECT_KEY_PART } from './constants';
import type { Map } from 'immutable';

const Favourite = ({
  project,
  currentUser,
  children
}: {
  project: Project;
  children: React.ReactNode;
  currentUser: Map<string, any>;
}) => {
  const [favouriteProjects, setFavouriteProjects] = useLocalStorage<Pick<Project, 'id'>[]>(
    `${LOCAL_STORAGE_FAV_PROJECT_KEY_PART}-${currentUser.get('id')}`,
    []
  );

  const isFavourite = favouriteProjects.some((favProject) => favProject.id === project.id);

  return (
    <span className="project-row">
      {children}
      <Tooltip
        id={`${project.id}`}
        tooltip={isFavourite ? 'Remove from starred' : 'Add to starred'}
      >
        <FontAwesomeIcon
          icon="star"
          onClick={() =>
            setFavouriteProjects(
              isFavourite
                ? favouriteProjects.filter((favProject) => favProject.id !== project.id)
                : [...favouriteProjects, { id: project.id }]
            )
          }
          className={isFavourite ? 'favourite-icon-active' : 'favourite-icon'}
        />
      </Tooltip>
    </span>
  );
};

export default Favourite;
