import React from 'react';
import PropTypes from 'prop-types';
import ExternalLink from './ExternalLink';

const LegalLinks = (props) => {
  return (
    <p>
      Keboola Group {new Date().getFullYear()}
      {props.showTermsAndConditions && (
        <>
          {' '}
          <ExternalLink href="https://www.keboola.com/terms-and-conditions">
            Terms and Conditions
          </ExternalLink>
        </>
      )}
      {props.showSubscriptionAgreement && (
        <>
          {' '}
          <ExternalLink href="https://www.keboola.com/software-subscription-agreement">
            Subscription Agreement
          </ExternalLink>
        </>
      )}
      {props.showPrivacyPolicy && (
        <>
          {' '}
          <ExternalLink href="https://www.keboola.com/privacy-policy">Privacy Policy</ExternalLink>
        </>
      )}
    </p>
  );
};

LegalLinks.propTypes = {
  showTermsAndConditions: PropTypes.bool,
  showSubscriptionAgreement: PropTypes.bool,
  showPrivacyPolicy: PropTypes.bool
};

LegalLinks.defaultProps = {
  showTermsAndConditions: true,
  showSubscriptionAgreement: true,
  showPrivacyPolicy: true
};

export default LegalLinks;
