import React from 'react';
import ReactDOM from 'react-dom';
import Immutable from 'immutable';

import './fa-setup';
import CurrentUser from './components/CurrentUser';
import NewProjectModal from './components/NewProjectModal';
import RegionSelect from './components/RegionSelect';
import LegalLinks from './components/LegalLinks';
import AgreementLinks from './components/AgreementLinks';
import LandingOverview from './components/LandingOverview';
import NewUiProjectsList from './components/ProjectList/ProjectList';
import AdminCurrentUser from './components/AdminCurrentUser';
import NewUiPendingInvitations from './components/PendingInvitations';
import StackIndicator from './components/StackIndicator';

export default {
  helpers: { React, ReactDOM, Immutable },
  parts: {
    CurrentUser,
    NewProjectModal,
    admin: {
      RegionSelect,
      LegalLinks,
      AgreementLinks,
      LandingOverview,
      StackIndicator,
      ProjectsList: NewUiProjectsList,
      CurrentUser: AdminCurrentUser,
      PendingInvitations: NewUiPendingInvitations
    }
  }
};
