import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Gravatar from './Gravatar';

const CurrentUser = (props) => {
  return (
    <div className="kbc-user">
      <Gravatar user={props.user} size={32} className="kbc-user-avatar" />
      <Dropdown id="react-layout-current-user-dropdown" pullRight>
        <Dropdown.Toggle noCaret bsStyle="link">
          <div className="kbc-user-wrapper">
            <div className="kbc-user-name">{props.user.get('name')}</div>
            <div className="kbc-user-email">{props.user.get('email')}</div>
          </div>

          <FontAwesomeIcon icon="angle-down" fixedWidth />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {props.canManageApps && (
            <MenuItem href={props.urlTemplates.get('manageApps')}>Manage Applications</MenuItem>
          )}
          <MenuItem key="changePassword" href={props.urlTemplates.get('changePassword')}>
            My Account &amp; Organizations
          </MenuItem>
          <MenuItem divider />
          <MenuItem href={props.urlTemplates.get('logout')} key="logout">
            Logout
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

CurrentUser.propTypes = {
  user: PropTypes.object.isRequired,
  urlTemplates: PropTypes.object.isRequired,
  canManageApps: PropTypes.bool.isRequired
};

export default CurrentUser;
