import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

const PendingInvitations = (props) => {
  if (!props.invitationsCount) {
    return null;
  }

  return (
    <a href="/admin/account/projects-invitations" className="no-underline">
      <Alert bsStyle="warning" className="alert-as-link">
        <strong>Pending Invitations</strong>
        <br />
        <span>
          You have <strong>{props.invitationsCount}</strong> pending{' '}
          <strong>invitation{props.invitationsCount > 1 ? 's' : ''}</strong>
          <br /> waiting for your response.
        </span>
      </Alert>
    </a>
  );
};

PendingInvitations.propTypes = {
  invitationsCount: PropTypes.number.isRequired
};

export default PendingInvitations;
