const ROOT = 'kbc-ui';

const CHANGE_EVENT_KEY = 'local-storage-changed';

const getStore = () => {
  const store = window.localStorage.getItem(ROOT);
  return store ? JSON.parse(store) : {};
};

const getItem = (key, defaultValue) => {
  const store = getStore();
  const value = store[key];

  return typeof value !== 'undefined' ? value : defaultValue;
};

const setItem = (key, value) => {
  window.localStorage.setItem(ROOT, JSON.stringify({ ...getStore(), [key]: value }));
  dispatchChange();
};

const dispatchChange = () => window.dispatchEvent(new Event(CHANGE_EVENT_KEY));

export { getItem, setItem, CHANGE_EVENT_KEY };
