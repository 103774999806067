import React from 'react';
import ExternalLink from './ExternalLink';
import overviewWebM from '../media/overview.webm';
import overviewMP4 from '../media/overview.mp4';
import overviewImage from '../media/overview.png';

const LandingOverview = () => {
  return (
    <div>
      <video className="intro-video" autoPlay muted loop playsInline poster={overviewImage}>
        <source src={overviewMP4} type="video/mp4;codecs=hvc1" />
        <source src={overviewWebM} type="video/webm" />
      </video>
      <div className="intro-video-text">
        <h2>Turn Raw Data Into Interactive Data Apps</h2>
        <p>
          Power up your business teams with self-serve data and free data engineers to focus on
          strategic projects.
        </p>
        <ExternalLink href="https://demo.keboola.com/app/data-apps" className="btn btn-default">
          Open Demo Project
        </ExternalLink>
      </div>
    </div>
  );
};

export default LandingOverview;
