import React from 'react';
import { Map } from 'immutable';
import MD5 from 'crypto-js/md5';
import PropTypes from 'prop-types';
import Avatar from 'boring-avatars';

const styles = {
  wrapper: {
    position: 'relative',
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  gravatar: {
    position: 'absolute',
    left: 0
  }
};

const Gravatar = (props) => {
  const hash = MD5(props.user.get('email').trim().toLowerCase());

  return (
    <span style={styles.wrapper} className={props.className}>
      <Avatar
        name={props.user.get('name')}
        variant="beam"
        colors={['#228DFF', '#004787', '#28D8FF', '#905BFF', '#A8C2E6']}
        size={props.size}
      />
      <img
        src={`https://secure.gravatar.com/avatar/${hash}?s=${props.size}&d=blank`}
        width={props.size}
        height={props.size}
        alt={props.user.get('name')}
        referrerPolicy="no-referrer"
        loading="lazy"
        className="img-circle"
        style={styles.gravatar}
      />
    </span>
  );
};

Gravatar.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  className: PropTypes.string,
  size: PropTypes.number
};

Gravatar.defaultProps = {
  size: 36
};

export default Gravatar;
