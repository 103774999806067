import React from 'react';
import Select from 'react-select';

import { STACKS } from '../constants';
import { getHost } from '../utils/getHost';
import { continentReadableName } from '../utils/stacks';
import LogoAWS from '../media/logo-aws.svg';
import LogoAzure from '../media/logo-azure.svg';
import LogoGCP from '../media/logo-gcp.svg';

const options = [
  {
    label: 'Azure',
    value: 'azure',
    disabled: true
  },
  {
    ...STACKS.NORTH_EUROPE_AZURE,
    value: STACKS.NORTH_EUROPE_AZURE.host,
    label: STACKS.NORTH_EUROPE_AZURE.host
  },
  {
    label: 'AWS',
    value: 'aws',
    disabled: true
  },
  {
    ...STACKS.EU_CENTRAL_1_AWS,
    value: STACKS.EU_CENTRAL_1_AWS.host,
    label: STACKS.EU_CENTRAL_1_AWS.host
  },
  {
    ...STACKS.US_EAST_1_AWS,
    value: STACKS.US_EAST_1_AWS.host,
    label: STACKS.US_EAST_1_AWS.host
  },
  {
    label: 'Google Cloud',
    value: 'gcp',
    disabled: true
  },
  {
    ...STACKS.EUROPE_WEST3_GCP,
    value: STACKS.EUROPE_WEST3_GCP.host,
    label: STACKS.EUROPE_WEST3_GCP.host
  },
  {
    ...STACKS.US_EAST_4_GCP,
    value: STACKS.US_EAST_4_GCP.host,
    label: STACKS.US_EAST_4_GCP.host
  }
];

const logo = {
  aws: LogoAWS,
  azure: LogoAzure,
  gcp: LogoGCP
};

const allowedHosts = [
  STACKS.NORTH_EUROPE_AZURE.host,
  STACKS.US_EAST_1_AWS.host,
  STACKS.EU_CENTRAL_1_AWS.host,
  STACKS.EUROPE_WEST3_GCP.host,
  STACKS.US_EAST_4_GCP.host
];

class RegionSelect extends React.Component {
  render() {
    const host = getHost();

    if (!allowedHosts.includes(host)) {
      return null;
    }

    return (
      <span title="Select region">
        <Select
          className="kbc-select-region"
          clearable={false}
          deleteRemoves={false}
          backspaceRemoves={false}
          searchable={false}
          arrowRenderer={null}
          editable={false}
          value={getHost()}
          onChange={this.onChange}
          options={options}
          valueRenderer={this.renderValue}
          optionRenderer={this.renderOption}
        />
      </span>
    );
  }

  renderValue = (option) => {
    return (
      <div className="custom-value flex flex-between">
        <div className="flex">
          <img src={logo[option.provider]} alt={option.value} loading="lazy" />
          <div className="continent">{continentReadableName[option.continent]}</div>
          {option.isPayg && <div className="payg-badge">Pay as you go</div>}
        </div>
        <div className="flex">
          <div className="region">{option.region}</div>
          <svg
            className="custom-caret"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              fill="#7C8594"
              d="M9.294 12.707c.39.39 1.024.39 1.415 0l3.998-3.998a.995.995 0 0 0 .216-1.09A1 1 0 0 0 13.998 7l-7.997.003a1.003 1.003 0 0 0-.709 1.709l3.999 3.998.003-.003Z"
            />
          </svg>
        </div>
      </div>
    );
  };

  renderOption = (option) => {
    if (option.disabled) {
      return (
        <div className="custom-option flex">
          <img src={logo[option.value]} alt={option.value} loading="lazy" />
          <div className="provider">{option.label}</div>
        </div>
      );
    }

    return (
      <div className="custom-option flex flex-between">
        <div className="flex">
          {continentReadableName[option.continent]}
          {option.isPayg && <div className="payg-badge">Pay as you go</div>}
        </div>
        <div className="region">{option.region}</div>
      </div>
    );
  };

  onChange = (region) => {
    if (window.location.host !== region.value) {
      window.location.href = `https://${region.value}/`;
    }
  };
}

export default RegionSelect;
