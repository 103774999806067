import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import matchByWords from '../../utils/matchByWords';
import { LOCAL_STORAGE_FAV_PROJECT_KEY_PART } from './constants';
import useLocalStorage from '../../utils/useLocalStorage';
import { List as ImmutableList } from 'immutable';
import List from './List';

const ProjectList = (props) => {
  const [searchQuery, setSearchQuery] = useState('');

  const [favouriteProjects] = useLocalStorage(
    `${LOCAL_STORAGE_FAV_PROJECT_KEY_PART}-${props.user.get('id')}`,
    []
  );

  const renderHelpText = () => {
    return (
      <p className="help-text-wrapper">
        <strong>Not seeing your project?</strong>
        <br />
        <span className="help-text">
          <a href="https://app.keboola.com/" className="underline">
            Select a different cloud provider or region
          </a>
          , or{' '}
          <a href="mailto:support@keboola.com" className="underline">
            contact us
          </a>
          .
        </span>
      </p>
    );
  };

  const renderNotMemberText = () => {
    if (props.hasPayAsYouGo) {
      return (
        <p className="help-text">
          Try to change stack,{' '}
          <a className="underline" href="/wizard">
            create a new project
          </a>{' '}
          or{' '}
          <a href="mailto:support@keboola.com" className="underline">
            contact us
          </a>{' '}
          to get started.
        </p>
      );
    }

    return (
      <p className="help-text">
        Try to change stack or{' '}
        <a href="mailto:support@keboola.com" className="underline">
          contact us
        </a>{' '}
        to get started.
      </p>
    );
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const hasResults = () => {
    return (
      props.organizations
        .filter((organization) => {
          let projects = organization.get('projects');

          if (searchQuery) {
            projects = projects.filter((project) => matchByWords(project.get('name'), searchQuery));
          }

          return projects.count() > 0;
        })
        .count() > 0
    );
  };

  if (!props.organizations.count()) {
    return (
      <div className="projects-list-box">
        <h2>You are not a member of any project yet.</h2>
        {renderNotMemberText()}
      </div>
    );
  }

  const favouriteProjectsIds = favouriteProjects.map((project) => project.id);

  const organizationsWithoutFavouriteProjects = props.organizations
    .map((organization) =>
      organization.update('projects', (prevProjects) =>
        prevProjects.filter(
          (project) =>
            !favouriteProjectsIds.includes(project.get('id')) &&
            (searchQuery ? matchByWords(project.get('name'), searchQuery) : true)
        )
      )
    )
    .filter((organization) => !organization.get('projects').isEmpty());

  const favouriteProjectsFiltered = props.organizations
    .flatMap((organization) => {
      return organization
        .get('projects', ImmutableList())
        .filter((project) => favouriteProjectsIds.includes(project.get('id')))
        .map((project) => project.set('organization', organization));
    })
    .filter((project) => {
      return matchByWords(project.get('name'), searchQuery);
    })
    .toJS();

  const getOrganizationUrl = (id) => {
    return props.urlTemplates.get('organization').replace('<%= organizationId %>', id);
  };

  return (
    <div className="projects-list-box">
      <div className="projects-list-searchbar">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="search-icon"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9998 16.0766L23.6736 20.7495C24.1096 21.1901 24.1096 21.9025 23.6689 22.3431L22.3423 23.6696C21.9063 24.1101 21.1937 24.1101 20.7531 23.6696L16.0793 18.9966C15.8683 18.7857 15.7512 18.4998 15.7512 18.1998V17.4358C14.0963 18.7294 12.0149 19.4981 9.75071 19.4981C4.36438 19.4981 0 15.1345 0 9.74905C0 4.36364 4.36438 0 9.75071 0C15.137 0 19.5014 4.36364 19.5014 9.74905C19.5014 12.0129 18.7326 14.0939 17.4388 15.7485H18.2029C18.5029 15.7485 18.7889 15.8656 18.9998 16.0766ZM3.75 9.74905C3.75 13.0675 6.43614 15.7485 9.75045 15.7485C13.0695 15.7485 15.7509 13.0628 15.7509 9.74905C15.7509 6.43062 13.0648 3.74963 9.75045 3.74963C6.43145 3.74963 3.75 6.43531 3.75 9.74905Z"
            fill="#C8CAD9"
          />
        </svg>
        <FormControl
          autoFocus
          type="text"
          placeholder="Search your projects"
          value={searchQuery}
          onChange={handleChangeSearchQuery}
        />
      </div>
      <div className="projects-list overflow-break-anywhere">
        {!hasResults() ? (
          <p className="organization-list list">No project found</p>
        ) : (
          <>
            {favouriteProjectsFiltered.length !== 0 && (
              <div className="organization-list list">
                <span className="title">{'Starred projects'}</span>
                <List
                  projects={favouriteProjectsFiltered}
                  currentUser={props.user}
                  urlTemplates={props.urlTemplates}
                  withOrganizationName
                  getOrganizationUrl={getOrganizationUrl}
                />
              </div>
            )}
            {organizationsWithoutFavouriteProjects
              .sortBy((organization) => organization.get('name').toLowerCase())
              .toArray()
              .map((organization) => (
                <div key={organization.get('id')} className="organization-list list">
                  {organization.get('hasAccess') ? (
                    <a href={getOrganizationUrl(organization.get('id'))} className="title">
                      {organization.get('name')}
                    </a>
                  ) : (
                    <span className="title">{organization.get('name')}</span>
                  )}
                  <List
                    projects={organization.get('projects').toJS()}
                    currentUser={props.user}
                    urlTemplates={props.urlTemplates}
                  />
                </div>
              ))}
          </>
        )}
        {renderHelpText()}
      </div>
    </div>
  );
};

ProjectList.propTypes = {
  organizations: PropTypes.object.isRequired,
  urlTemplates: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  hasPayAsYouGo: PropTypes.bool
};

export default ProjectList;
