const STACKS = {
  NORTH_EUROPE_AZURE: {
    id: 'com-keboola-azure-north-europe',
    region: 'north-europe',
    host: 'connection.north-europe.azure.keboola.com',
    provider: 'azure',
    continent: 'europe',
    isPayg: true
  },
  EU_CENTRAL_1_AWS: {
    id: 'kbc-eu-central-1',
    region: 'eu-central-1',
    host: 'connection.eu-central-1.keboola.com',
    provider: 'aws',
    continent: 'europe'
  },
  US_EAST_1_AWS: {
    id: 'kbc-us-east-1',
    region: 'us-east-1',
    host: 'connection.keboola.com',
    provider: 'aws',
    continent: 'us'
  },
  EUROPE_WEST2_GCP: {
    id: 'com-keboola-gcp-europe-west2',
    region: 'europe-west2',
    host: 'connection.europe-west2.gcp.keboola.com',
    provider: 'gcp',
    continent: 'europe'
  },
  EUROPE_WEST3_GCP: {
    id: 'com-keboola-gcp-europe-west3',
    region: 'europe-west3',
    host: 'connection.europe-west3.gcp.keboola.com',
    provider: 'gcp',
    continent: 'europe'
  },
  US_EAST_4_GCP: {
    id: 'com-keboola-gcp-us-east4',
    region: 'us-east4',
    host: 'connection.us-east4.gcp.keboola.com',
    provider: 'gcp',
    continent: 'us',
    isPayg: true
  }
} as const;

export { STACKS };
