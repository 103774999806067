import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = (props) => {
  return (
    <a href={props.href} className={props.className} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default ExternalLink;
