const shouldShowTemplate = (template, maintainerBackends) => {
  switch (template.get('defaultBackend')) {
    case 'snowflake':
      return maintainerBackends.get('hasSnowflake');
    case 'bigquery':
      return maintainerBackends.get('hasBigquery');
    case 'exasol':
      return maintainerBackends.get('hasExasol');
    case 'teradata':
      return maintainerBackends.get('hasTeradata');
    case 'synapse':
      return maintainerBackends.get('hasSynapse');
    case 'redshift':
      return maintainerBackends.get('hasRedshift');
    default:
      return false;
  }
};

export { shouldShowTemplate };
