export default function (texts: string | string[], query: string) {
  if (!query.trim()) {
    return true;
  }

  const inputs = ([] as string[])
    .concat(texts)
    .filter(Boolean)
    .map((input) => input.toLowerCase());

  const queries = query.trim().toLowerCase().split(' ');

  return queries.every((word) => inputs.some((input) => input.includes(word)));
}
