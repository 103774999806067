import { STACKS } from '../constants';

export const getStackFromHost = (host: string) =>
  Object.values(STACKS).find((stack) => stack.host === host);

export const providerReadableName = {
  aws: 'AWS',
  azure: 'Azure',
  gcp: 'Google Cloud'
};

export const continentReadableName = {
  europe: 'Europe',
  us: 'United States'
};
