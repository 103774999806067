import React from 'react';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';

type Props = {
  id: string;
  tooltip: string | React.ReactElement;
  children: React.ReactNode;
};

const Tooltip = (props: Props) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<BootstrapTooltip id={props.id}>{props.tooltip}</BootstrapTooltip>}
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default Tooltip;
