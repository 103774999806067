import React from 'react';
import type { Map } from 'immutable';

import Favourite from './Favourite';
import type { Project } from './types';
import Tooltip from '../Tooltip';

const List = ({
  projects,
  urlTemplates,
  withOrganizationName = false,
  currentUser,
  getOrganizationUrl
}: {
  projects: Project[];
  urlTemplates: Map<string, any>;
  withOrganizationName?: boolean;
  currentUser: Map<string, any>;
  getOrganizationUrl?: (organizationId: string) => string;
}) => {
  const sortedProjects = projects.sort((a, b) =>
    a.name.localeCompare(b.name, [], { numeric: true, sensitivity: 'base' })
  );

  const projectUrl = (id: string | number) => {
    return urlTemplates.get('project').replace('<%= projectId %>', id);
  };

  return (
    <ul className="ul">
      {sortedProjects.map((project) => {
        return (
          <li key={`proj-${project.id}`}>
            <Favourite project={project} currentUser={currentUser}>
              <div className="project-container">
                {project.isDisabled ? (
                  <Tooltip
                    id={`${project.id}`}
                    tooltip={`Project is disabled. ${project?.disabledReason}`}
                  >
                    <span className="disabled project-link">{project.name}</span>
                  </Tooltip>
                ) : (
                  <a href={projectUrl(project.id)} className="project-link">
                    {project.name}
                  </a>
                )}
                {withOrganizationName &&
                  project.organization &&
                  (project.organization.hasAccess && getOrganizationUrl ? (
                    <a
                      href={getOrganizationUrl(project.organization.id)}
                      className="organization-name-link"
                    >
                      <p className="organization-name">{project.organization.name}</p>
                    </a>
                  ) : (
                    <p className="organization-name">{project.organization.name}</p>
                  ))}
              </div>
            </Favourite>
          </li>
        );
      })}
    </ul>
  );
};

export default List;
