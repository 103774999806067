import React from 'react';
import ExternalLink from './ExternalLink';

const AgreementLinks = () => {
  return (
    <p className="support">
      By creating the account you agree to our{' '}
      <ExternalLink href="https://www.keboola.com/terms-and-conditions">
        Terms and Conditions
      </ExternalLink>
      ,{' '}
      <ExternalLink href="https://www.keboola.com/master-software-subscription-agreement">
        Subscription Agreement
      </ExternalLink>{' '}
      and{' '}
      <ExternalLink href="https://www.keboola.com/master-software-subscription-agreement#6-data-management">
        Privacy Policy
      </ExternalLink>
    </p>
  );
};

export default AgreementLinks;
