import React from 'react';

import LogoAWS from '../media/logo-aws.svg';
import LogoAzure from '../media/logo-azure.svg';
import LogoGCP from '../media/logo-gcp.svg';
import { getHost } from '../utils/getHost';
import { providerReadableName, getStackFromHost, continentReadableName } from '../utils/stacks';

const logo = {
  aws: LogoAWS,
  azure: LogoAzure,
  gcp: LogoGCP
};

const StackIndicator = () => {
  const host = getHost();
  const stack = getStackFromHost(host);

  if (!stack) {
    return null;
  }

  const provider = stack.provider;
  const providerName = providerReadableName[provider];
  const continent = continentReadableName[stack.continent];

  return (
    <div className="stack-indicator">
      <div>
        <div className="stack-indicator-wrapper">
          <img src={logo[provider]} alt={provider} loading="lazy" />
          <div>
            <strong>
              {providerName} - {continent}
            </strong>
            <p className="stack-indicator-region">{stack.region}</p>
          </div>
        </div>
      </div>

      <a href="https://app.keboola.com" className="underline">
        Change Stack
      </a>
    </div>
  );
};

export default StackIndicator;
